import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import uniqueId from 'uniqid';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwiperCore, { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Header from '../components/Header';
import Modal from '../components/Modal';

import Logo from '../assets/YAIR_LOGO.svg';

const ns = 'work-page';

const WorkPage = ({ location, data }) => {
  SwiperCore.use([EffectFade, Navigation]);

  const {
    home: {
      frontmatter: {
        projects,
      },
    },
  } = data;
  const rootClassnames = classNames({
    [`${ns}`]: true,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [logoPosition, setLogoPosition] = useState(false);

  const handleClick = (index) => {
    setIsOpen(true);
    setContent(index);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoPosition(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout location={location} title="YAIR">
      <Seo title="WORKS" />
      <div className={rootClassnames}>
        <Header slideUp={isOpen} />
        <div className="container">
          {projects.length > 0 && !isOpen && (
            <section className={`${ns}__section ${ns}__projects`}>
              {projects.map((project, index) => (
                <div className={`${ns}__project`} key={uniqueId('yr')}>
                  <div className={`${ns}__featured-image`}>
                    <button type="button" onClick={() => handleClick(index)}>
                      <GatsbyImage image={project.featured_image.childImageSharp.gatsbyImageData} alt="" />
                    </button>
                    {project.year_made && (
                      <p>{project.year_made}</p>
                    )}
                  </div>
                </div>
              ))}
            </section>
          )}

          {isOpen && (
            <section className={`${ns}__section`}>
              <Modal
                onRequestClose={() => { setIsOpen(false); }}
                isOpen={isOpen}
              >
                <div className={`${ns}__content`}>
                  <div className={`${ns}__slider`}>
                    <Swiper
                      className={`${ns}__slider-wrapper`}
                      fadeEffect={{ crossFade: true }}
                      loop
                      slidesPerView={1}
                      style={{ position: 'initial' }}
                      navigation={{
                        nextEl: `.${ns}__navigation-next`,
                        prevEl: `.${ns}__navigation-prev`,
                      }}
                    >
                      <div className={`${ns}__navigation`}>
                        <button className={`${ns}__navigation-prev`} type="button">
                          <p>PREVIOUS</p>
                        </button>
                        <button className={`${ns}__navigation-next`} type="button">
                          <p>NEXT</p>
                        </button>
                      </div>
                      {projects[content].slides?.length > 0
                        && projects[content].slides.map((slide) => (
                          <SwiperSlide
                            className={`${ns}__slide`}
                            key={uniqueId('ys')}
                          >
                            <GatsbyImage image={slide.image.childImageSharp.gatsbyImageData} alt="" />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className={`${ns}__slider-details`}>
                    <div className={`${ns}__details-column`}>
                      {projects[content].title && content !== null && (
                        <p>{projects[content].title}</p>
                      )}
                      {projects[content].year_made && content !== null && (
                        <p>{projects[content].year_made}</p>
                      )}
                    </div>
                    <div className={`${ns}__details-column`}>
                      {projects[content].material && content !== null && (
                        <p>{projects[content].material}</p>
                      )}
                      {projects[content].scale && content !== null && (
                        <p>{projects[content].scale}</p>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
            </section>
          )}

        </div>
        <div className={`${ns}__logo ${logoPosition ? `${ns}__logo-down` : ''} `}>
          <div className={`${ns}__logo-container`}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default WorkPage;

export const workPageQuery = graphql`
  query workPage($id: String!) {
    home: markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        projects {
          title
          year_made
          scale
          material
          featured_image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
          slides {
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED,
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`;

WorkPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
