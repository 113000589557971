import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

// Setup app element for react-modal
ReactModal.setAppElement('#___gatsby');

const ns = 'site-modal';

const Modal = ({
  children,
  variant,
  contentLabel = '',
  isOpen = false,
  onRequestClose,
}) => {
  const [openState, setIsOpenState] = useState(true);

  const rootClassnames = classNames({
    [`${ns}`]: true,
    [`${ns}--${variant}`]: variant,
  });

  const overlayClassName = classNames({
    [`${ns}__overlay`]: true,
    [`${ns}__overlay--${variant}`]: variant,
  });

  const handleCloseModal = () => {
    if (onRequestClose && typeof onRequestClose === 'function') {
      onRequestClose();
    } else {
      setIsOpenState(false);
    }
  };

  useEffect(() => {
    setIsOpenState(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      contentLabel={contentLabel}
      className={rootClassnames}
      bodyOpenClassName={`${ns}--open`}
      overlayClassName={overlayClassName}
      isOpen={openState}
      onRequestClose={handleCloseModal}
      closeTimeoutMS={400}
    >
      <div className={`${ns}__content-inner`}>
        <button
          className={`${ns}__close`}
          onClick={handleCloseModal}
          type="button"
        >
          EXIT
        </button>
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;

Modal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  contentLabel: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onRequestClose: PropTypes.func,
};
